import get from "lodash/get";
import range from "lodash/range";
import { CABINS_CODE, PASSENGER_TYPE, TRANSPORTATION_TYPES } from "app/constants";
import subHours from "date-fns/subHours";
import { stringify } from "qs";
import { sendTagOnSDPSearch } from "app/utils/analytics";
import { isEmpty, orderBy } from "lodash";

export const buildSDPQuotePayload = ({
	queryParams = {},
	partnerCode,
	destinations = [],
	sdpFunnelFlags,
}) => {
	const requestBody = {
		departureCityCode: queryParams.departureCityCode,
		departureDate: Number(queryParams.departureDate),
		duration: Number(queryParams.duration),
		cabin: queryParams.cabinCode,
		partnerCode,
	};

	let destinationData = {};

	if (queryParams.destinationIATA) {
		destinationData = destinations.find(destination =>
			destination?.airportIATAs?.includes(queryParams.destinationIATA)
		);
		requestBody.destinationResort = Number(destinationData.code);
	} else {
		requestBody.destinationResort = Number(queryParams.destinationResortCode);
	}

	if (sdpFunnelFlags) {
		requestBody.abTests = sdpFunnelFlags;
	}

	const newOccupancies =
		queryParams.occupancies &&
		queryParams.occupancies.map(occupancy => {
			if (occupancy.childrenBirthdates) {
				const childrenBirthdates = occupancy.childrenBirthdates.map(childrenBirthdate => {
					return {
						day:
							childrenBirthdate.day || childrenBirthdate.day === 0
								? Number(childrenBirthdate.day)
								: undefined,
						month:
							childrenBirthdate.month || childrenBirthdate.month === 0
								? Number(childrenBirthdate.month) + 1
								: undefined,
						year:
							childrenBirthdate.year || childrenBirthdate.year === 0
								? Number(childrenBirthdate.year)
								: undefined,
					};
				});
				return {
					adults: Number(occupancy.adults),
					children: Number(occupancy.children),
					childrenBirthdates,
				};
			}
			return { adults: Number(occupancy.adults) };
		});

	requestBody.occupancies = newOccupancies;
	return requestBody;
};

export const buildStopoverQuotePayload = ({
	queryParams = {},
	marketingCode,
	destinations = [],
	sdpFunnelFlags,
}) => {
	const requestBody = {
		origin: queryParams.departureCityCode,
		departureDate: Number(queryParams.departureDate),
		duration: Number(queryParams.duration),
		cabin: CABINS_CODE[queryParams.cabinCode],
		stopOverConfig: {
			stopOverId: Number(queryParams.stopoverCityId),
			stopAt: Number(queryParams.stopoverDirection),
		},
		marketingCode,
	};

	let destinationData = {};

	if (queryParams.destinationIATA) {
		destinationData = destinations.find(destination =>
			destination?.airportIATAs?.includes(queryParams.destinationIATA)
		);
		requestBody.destinationId = Number(destinationData.code);
	} else {
		requestBody.destinationId = Number(queryParams.destinationResortCode);
	}

	if (sdpFunnelFlags) {
		requestBody.abTests = sdpFunnelFlags;
	}

	const newOccupancies =
		queryParams.occupancies &&
		queryParams.occupancies.map(occupancy => {
			if (occupancy.childrenBirthdates) {
				const childrenBirthdates = occupancy.childrenBirthdates.map(childrenBirthdate => {
					return {
						day:
							childrenBirthdate.day || childrenBirthdate.day === 0
								? Number(childrenBirthdate.day)
								: undefined,
						month:
							childrenBirthdate.month || childrenBirthdate.month === 0
								? Number(childrenBirthdate.month) + 1
								: undefined,
						year:
							childrenBirthdate.year || childrenBirthdate.year === 0
								? Number(childrenBirthdate.year)
								: undefined,
					};
				});
				return {
					adults: Number(occupancy.adults),
					children: Number(occupancy.children),
					childrenBirthdates,
				};
			}
			return { adults: Number(occupancy.adults) };
		});

	requestBody.occupancies = newOccupancies;
	return requestBody;
};

export const getFlightNegotiatedLabelKey = (
	flight = {},
	flightOptions = {}, // TODO A supprimer car ne semble pas être utilisé
	luggageAddedByUser = 0 // TODO A supprimer car ne semble pas être utilisé
) => {
	const isDirectFlight =
		get(flight, "outbound.legs.length", 0) === 1 && get(flight, "inbound.legs.length", 0) === 1;

	const isLuggageIncluded = !isEmpty(flightOptions)
		? flightOptions?.flightBagageOption?.included === true
		: flight?.flightOptions?.flightBagageOption?.included;
	const isTravelByTrain = flight.type === TRANSPORTATION_TYPES.TRAIN;
	const isLuggageAddedByUser = luggageAddedByUser > 0;
	const isLuggageIncludedQuantity = !isEmpty(flightOptions)
		? flightOptions?.flightBagageOption?.quantity
		: flight?.flightOptions?.flightBagageOption?.quantity;

	const isPluralMessage = isLuggageIncludedQuantity > 1 || luggageAddedByUser > 1;

	const directFlightLuggageFlightNegotiatedLabel = isPluralMessage
		? "sdp.listing.product.direct.luggage.flight.negotiated.label.plural"
		: "sdp.listing.product.direct.luggage.flight.negotiated.label.singular";

	const flightLuggageFlightNegotiatedLabel = isPluralMessage
		? "sdp.listing.product.luggage.flight.negotiated.label.plural"
		: "sdp.listing.product.luggage.flight.negotiated.label.singular";

	let flightNegociatedLabel = isTravelByTrain
		? "sdp.listing.product.transport.negotiated.label"
		: "sdp.listing.product.flight.negotiated.label";

	if (isDirectFlight && (!isLuggageIncluded && !isLuggageAddedByUser)) {
		flightNegociatedLabel = isTravelByTrain
			? "sdp.listing.product.direct.transport.negotiated.label"
			: "sdp.listing.product.direct.flight.negotiated.label";
	} else if (!isDirectFlight && (isLuggageIncluded || isLuggageAddedByUser)) {
		flightNegociatedLabel = isTravelByTrain
			? "sdp.listing.product.luggage.transport.negotiated.label"
			: flightLuggageFlightNegotiatedLabel;
	} else if (isDirectFlight && (isLuggageIncluded || isLuggageAddedByUser)) {
		flightNegociatedLabel = isTravelByTrain
			? "sdp.listing.product.direct.luggage.transport.negotiated.label"
			: directFlightLuggageFlightNegotiatedLabel;
	}

	return flightNegociatedLabel;
};

export function buildSDPBudgetFilterValues(accommodationsList, basePrice, travellersTotalCount) {
	const allPrices = accommodationsList
		.map(accommodation => {
			return getAccommodationPrice(accommodation, basePrice, travellersTotalCount);
		})
		.filter(_ => _)
		.sort((a, b) => a - b);

	// Quartiles et on regarde si c'est la même valeur alors on arrondi à 50 supérieur
	const q1 = round50(getPercentile(allPrices, 25));
	const median = round50(getPercentile(allPrices, 50));
	const q3 = round50(getPercentile(allPrices, 75));

	const isOneSameFilter = q1 === median || median === q3;

	return isOneSameFilter
		? []
		: [{ min: 0, max: q1 }, { min: q1, max: median }, { min: median, max: q3 }, { min: q3 }];
}

export function getAccommodationPrice(
	accommodation,
	basePrice,
	travellersTotalCount,
	flightUpgradePrice = 0,
	luggageUpgradePrice = 0
) {
	let total = basePrice;
	if (accommodation.accommodationItems) {
		// get room upgrade price
		const includedAccommodationItem = accommodation.accommodationItems[0];
		const upgradePrice = includedAccommodationItem.upgradePrice || 0;
		total = total + upgradePrice;

		// get board data
		// TODO delete .codes which is replaced by .boards
		const boards = includedAccommodationItem.boards || includedAccommodationItem.codes;
		let boardToDisplay = boards[0] || {};
		const boardUpgradePrice = boardToDisplay.upgradePrice || 0;
		total += boardUpgradePrice;

		total += flightUpgradePrice;

		total += luggageUpgradePrice;

		total = Math.ceil(total / travellersTotalCount);
	}

	return total;
}

export function getPercentile(data, percentile) {
	let index = (percentile / 100) * data.length;
	let result;
	if (Math.floor(index) === index) {
		result = (data[index - 1] + data[index]) / 2;
	} else {
		result = data[Math.floor(index)];
	}
	return result;
}

export function round50(x) {
	return Math.round(x / 50) * 50;
}

export const mapOcupanciesToPassengers = (occupancies = []) => {
	const passsengers = [];
	let adultIndex = 1,
		childrenIndex = 1;

	occupancies.forEach(occupancy => {
		if (occupancy?.adults > 0) {
			range(0, occupancy.adults).forEach(() => {
				passsengers.push({ type: PASSENGER_TYPE.ADULT, index: adultIndex });
				adultIndex += 1;
			});
		}
		if (occupancy?.children > 0) {
			range(0, occupancy?.children).forEach(index => {
				passsengers.push({
					type: PASSENGER_TYPE.CHILD,
					index: childrenIndex,
					birthdate: occupancy?.childrenBirthdates[index],
				});
				childrenIndex += 1;
			});
		}
	});

	return orderBy(passsengers, ["type"], ["asc"]);
};

export const buildSDPSearchPayload = data => {
	const occupancies = data.occupancies.map(occupancy => {
		const childrenBirthdates =
			occupancy.childrenBirthdates &&
			occupancy.childrenBirthdates.map(birthdates => {
				return birthdates;
			});

		return {
			...occupancy,
			childrenBirthdates,
		};
	});

	// ex Paris : Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
	let departureDate = data.travelDates?.departureDate;
	// => Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
	departureDate = subHours(departureDate, departureDate.getTimezoneOffset() / 60);
	// departureDate.geTime() gives 11/06/2021
	// new Date(departureDate.geTime()) is stored in booking reducer abd used to initialize the form and gives Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)

	const queryData = {
		departureCityCode: data.departureCity.code,
		destinationResortCode: data.destinationResort.code,
		cabinCode: data.cabin.code,
		duration: data.duration?.code,
		departureDate: departureDate.getTime(),
		occupancies: occupancies,
	};

	// TODO endDate may not be available
	let endDate = data.travelDates?.endDate;
	if (endDate) {
		endDate = subHours(endDate, endDate.getTimezoneOffset() / 60);
		queryData.endDate = endDate.getTime();
	}

	// STOPOVER
	if (data.stopoverCity?.code) {
		queryData.stopoverCityId = data.stopoverCity.code;
	}

	// STOPOVER
	if (data.stopoverDirection) {
		queryData.stopoverDirection = data.stopoverDirection.code;
	}

	const queryParams = stringify(queryData, {
		encodeValuesOnly: true,
		arrayFormat: "indices",
	});

	sendTagOnSDPSearch(queryData);

	return queryParams;
};
